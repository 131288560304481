import React, { useState } from "react";
import Contact from "../Contact/Contact";
import Footer from "../Footer/Footer";
import Landing from "../Landing/Landing";
import Navbar from "../NavBar/Navbar";
import NavbarMenu from "../NavbarMenu/NavbarMenu";

import SideBar from "../SideBar/SideBar";
import "./Content.css";
import "./Results.css";
import { Card, Col, Row } from "react-bootstrap";

import { ImBooks } from "react-icons/im";

import bookcard from "../../image/bookcard.jfif";
import stationary from "../../image/stationary.jpg";

function Books(props) {
  const { cover, sidebar_data, side_heading, side_sub_heading } = props;
  const [isScrolled, setIsScrolled] = useState(false);
  const [isShow, steIsShow] = useState(false);
  const handleScroll = (e) => {
    if (e.target.scrollTop > 0 && !isScrolled) {
      setIsScrolled(true);
    }
    if (e.target.scrollTop === 0) {
      setIsScrolled(false);
    }
  };
  const handleClick = () => {
    steIsShow(!isShow);
    console.log(isShow);
  };

  return (
    <div className="content_main" onScroll={handleScroll}>
      <Navbar isScrolled={isScrolled} handleClick={handleClick} />
      <NavbarMenu isShow={isShow} handleClick={handleClick} />
      <Landing video={false} cover={cover} />
      <div className="contentmain_cont">
        <div style={{ width: "min-content" }}>
          <SideBar
            sidebar_data={sidebar_data}
            side_heading={side_heading}
            side_sub_heading={side_sub_heading}
          />
          <Contact />
        </div>
        <div>
          <div className={`f-2 f-500 mb-3`} style={{ color: "#1FDC00ED" }}>
            <ImBooks className="admissions__image" />
            {/* <img
              className="admissions__image"
              src={GiBookshelf}
              style={{ marginRight: "1rem" }}
            />{" "} */}
            Booklist
          </div>
          <Row>
            <Col>
              {/* <div className="row">
              <div className="col-lg-5">
                <div className="result-btn mt-2">
                  <a href={require("../../pdf/booklist.pdf")}>
                    <button className="tenth_result">Books</button>
                  </a>
                </div>
              </div>
            </div> */}
              <a href={require("../../pdf/booklist.pdf")}>
                <Card
                  style={{
                    width: "15rem",
                    height: "15rem",
                    boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                    borderRadius: "10px",
                    margin: "auto",
                    marginTop: "2rem",
                    marginBottom: "2rem",
                  }}
                >
                  <Card.Img
                    src={bookcard}
                    style={{ height: "12rem", marginLeft: "-0.5px" }}
                  />
                  <p style={{ textAlign: "center", marginTop: "1rem" }}>
                    <b>Booklist 2024-25</b>
                  </p>
                </Card>
              </a>
            </Col>
            <Col>
              <a href={require("../../pdf/stationary.pdf")}>
                <Card
                  style={{
                    width: "15rem",
                    height: "15rem",
                    boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                    borderRadius: "10px",
                    margin: "auto",
                    marginTop: "2rem",
                    marginBottom: "2rem",
                  }}
                >
                  <Card.Img
                    src={stationary}
                    style={{ height: "12rem", marginLeft: "-0.5px" }}
                  />
                  <p style={{ textAlign: "center", marginTop: "1rem" }}>
                    <b> Stationary Items 2024-25</b>
                  </p>
                </Card>
              </a>
            </Col>
          </Row>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default Books;
